import AddIcon from "@/components/icons/AddIcon";
import MinusIcon from "@/components/icons/MinusIcon";
import Link from "next/link";

import { useState } from "react";
import { useTranslation } from "next-i18next";
import Image from "@/components/global/Image";

const FooterLinks = ({ title, data = [], has_imgs = false }) => {
  const [active, setActive] = useState(false);
  const { t } = useTranslation("common");
  return (
    <div className="mb-4 col-span-full md:col-span-3 lg:col-span-1">
      <button
        onClick={() => setActive(!active)}
        className="flex items-center justify-between w-full mb-4"
      >
        <span className="font-bold max-sm:text-xs">{title}</span>
        {!active ? (
          <AddIcon className="size-3 sm:size-5 md:hidden" />
        ) : (
          <MinusIcon className="size-3 sm:size-5 md:hidden" />
        )}
      </button>
      <div
        className={`${
          active ? "block" : "hidden"
        } md:block *:mb-3 *:opacity-90 text-sm`}
      >
        {data?.map((cat, i) => (
          <Link
            href={`/${cat?.url_key || "/"}`}
            key={i}
            className="block max-sm:text-xs"
          >
            {t(cat?.name)}
          </Link>
        ))}
      </div>
      {has_imgs ? (
        <div
          className={`${active ? "flex" : "hidden"} md:flex items-center gap-4`}
        >
          <Link
            href="https://maroof.sa/businesses/details/73627"
            target="_blank"
          >
            <Image
              src="/imgs/footer/maarouf.png"
              className="object-contain cursor-pointer size-auto"
              alt="Maarouf Img!"
            />
          </Link>
          <Link href="/backcomfort-vat-cerificate">
            <Image
              src="/imgs/footer/vat.png"
              className="object-contain size-auto"
              alt="Maarouf Img!"
            />
          </Link>
          <Link
            href="https://qr.mc.gov.sa/sales/license-details?qr=i/4ftrb8TBH+UZAEdchuLA=="
            target="_blank"
          >
            <Image
              src="/imgs/footer/licence-ecommerce.png"
              className="object-contain size-auto"
              alt="Maarouf Img!"
            />
          </Link>
          <Link
            href="https://qr.mc.gov.sa/info/review?lang=ar&q=PvKOESDta2O1A96zxU61lQ=="
            target="_blank"
          >
            <Image
              src="/imgs/footer/qr_cr.png"
              className="object-contain cursor-pointer size-auto"
              alt="Maarouf Img!"
            />
          </Link>
          <Link href="/static/sbc">
            <Image
              src="/imgs/footer/sbc.png"
              className="object-contain cursor-pointer size-14"
              alt="Maarouf Img!"
            />
          </Link>
        </div>
      ) : null}
    </div>
  );
};

export default FooterLinks;
